/* You can add global styles to this file, and also import other style files */

.mrsu-color-green {
  color: rgb(82, 196, 26) !important;
}

.mrsu-color-orange {
  color: #fa8c16 !important;
}

.mrsu-flex-row {
  display: flex;
  flex-direction: row;
}

.mrsu-flex-fill {
  flex: 1;
}

.ant-layout.mrsu-inner-layout {
  padding: 24px 0;
  background: #fff;
}

.mrsu-inner-content {
  padding: 0 24px;
  min-height: 280px;
}

.sider-menu {
  height: 100%;
}

nz-breadcrumb.ant-breadcrumb {
  margin: 0 0 16px 0;
}

.mrsu-form {
  max-width: 600px;
}

.mrsu-no-breadcrumbs-inner-layout {
  margin-top: 47px;
}

.mrsu-position-relative {
  position: relative;
}

.mrsu-content-loading-container {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.mrsu-form-divider {
  width: auto !important;
  min-width: auto !important;
  margin-top: 0 !important;
}
